<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row no-gutters>
        <span> Set Payment Reference/Date For {{ teammemberName }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close" fab class="error" x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col md="5" sm="4" cols="6">
          <b>Payrun Date:</b>
        </v-col>
        <v-col md="7" sm="8" cols="6"> {{ formatDate(PayRunFromDate) }} - {{ formatDate(PayRunToDate) }}</v-col>
      </v-row>
      <v-row @click="fillAUDAmount()">
        <v-col md="5" sm="4" cols="6">
          <b>Total {{ currency }} Amount: </b>
        </v-col>
        <v-col md="7" sm="8" cols="6"> {{ formatNumber(PayRunAUDTotalPay) }} {{ currency }} </v-col>
      </v-row>
      <v-row @click="fillLCAmount()">
        <v-col md="5" sm="4" cols="6">
          <b>Total {{ PayRunExchangeCurrency }} Amount: </b>
        </v-col>
        <v-col md="7" sm="8" cols="6"> {{ formatNumber(PayRunLCTotalPay) }} {{ PayRunExchangeCurrency }} </v-col>
      </v-row>
      <v-form ref="frmPayment" v-model="valid" lazy-validation class="ref-form">
        <v-row>
          <v-col md="4">
            <TextField :rules="[rules.required]" label="Payment Refrence" v-model="model.PaymentRefNo" outlined dense></TextField>
          </v-col>
          <v-col md="2">
            <DateInputField
              id="start-date"
              :rules="[rules.required]"
              label="Payment Date"
              first-day-of-week="1"
              v-model="model.PaymentDate"
              hide-actions
              placeholder="Date"
              prepend-icon=""
            />
          </v-col>
          <v-col md="2">
            <CurrencyTextFieldV2
              :rules="[rules.required, rules.currency, rules.notZero]"
              dense
              outlined
              :label="`Amount ${currency}`"
              v-model="model.AUDPaymentAmount"
              :readonly="isReadOnly"
            />
          </v-col>
          <v-col md="2">
            <CurrencyTextFieldV2
              :rules="[rules.required, rules.currency, rules.notZero]"
              dense
              outlined
              :label="`Amount ${PayRunExchangeCurrency}`"
              v-model="model.LCPaymentAmount"
              :readonly="isReadOnly"
            />
          </v-col>

          <v-col md="2" sm="2" cols="12">
            <v-btn fab x-small color="primary" class="mt-8" @click="confirmAddPayment"><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-card-text v-if="PayRunPayments">
        <v-data-table :headers="getColumns()" :items="PayRunPayments" item-key="id" class="elevation-1 custom-table-row">
          <template v-slot:item.PaymentDate="{ item }">
            {{ formatDate(item.PaymentDate) }}
          </template>
          <template v-slot:item.PaymentRefNo="{ item }">
            {{ item.PaymentRefNo }}
          </template>
          <template v-slot:item.LCTotalPay="{ item }">
            {{ formatNumber(item.LCTotalPay) }} {{ item.ExchangeCurrency ? item.ExchangeCurrency : "TMN" }}
          </template>
          <template v-slot:item.AUDTotalPay="{ item }"> {{ formatNumber(item.AUDTotalPay) }} {{ currency }} </template>
        </v-data-table>
      </v-card-text>
      <v-row>
        <v-col md="12" class="text-right">
          <v-btn color="error" @click="close"> <v-icon>mdi-close</v-icon> Cancel </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog persistent v-model="showAddPaymentConfirmDialog" @keydown.esc="showAddPaymentConfirmDialog = false" max-width="400px">
      <confirm-dialog
        :loading="loading"
        @confirm="addPayment"
        @cancel="
          showAddPaymentConfirmDialog = false;
          loading = false;
        "
        :message="confirmModel"
      >
      </confirm-dialog>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import rules from "shared-components/src/utils/validations";
import { PayRunApi, PayrunPayment } from "shared-components/src/services/openApi";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";

var payrunApi = new PayRunApi();

export default defineComponent({
  components: {
    ConfirmDialog,
  },
  props: ["selectedPayRun", "teammemberName"],
  data() {
    return {
      rules,
      paymentDatePickerMenu: false,
      PayRunExchangeCurrency: this.selectedPayRun.ExchangeCurrency,
      PayRunLCTotalPay: this.selectedPayRun.LCTotalPay,
      PayRunAUDTotalPay: this.selectedPayRun.AUDTotalPay,
      PayRunFromDate: this.selectedPayRun.FromDate,
      PayRunToDate: this.selectedPayRun.ToDate,
      PayRunPayments: this.selectedPayRun.Payments,
      model: {
        PaymentDate: null as Date | null,
        PaymentRefNo: null as string | null,
        LCPaymentAmount: 0,
        AUDPaymentAmount: 0,
      },
      loading: false,
      valid: true,
      confirmModel: {
        title: "Add new payment",
        text: "Are you sure?",
      },
      showAddPaymentConfirmDialog: false,
    };
  },
  methods: {
    getColumns() {
      return [
        { title: "Payment Date", value: "PaymentDate", sortable: false },
        { title: "Payment Reference", value: "PaymentRefNo", sortable: false },
        { title: `Payment ${this.currency} Amount`, value: "AUDTotalPay", sortable: false, align: "center" },
        { title: `Payment ${this.PayRunExchangeCurrency} Amount`, value: "LCTotalPay", sortable: false, align: "center" },
      ];
    },
    fillAUDAmount() {
      this.model.AUDPaymentAmount = this.PayRunAUDTotalPay;
    },
    fillLCAmount() {
      this.model.LCPaymentAmount = this.PayRunLCTotalPay;
    },
    close(): void {
      this.$emit("close");
    },
    setLCPaymentAmount($event: any): void {
      this.model.LCPaymentAmount = Utils.currencyToNumber($event);
    },
    async confirmAddPayment() {
      const isValid = await (this.$refs.frmPayment as any).validate();
      if (this.model && isValid.valid) {
        this.showAddPaymentConfirmDialog = true;
      }
    },
    async addPayment() {
      try {
        this.loading = true;
        const payment = {
          LCTotalPay: this.model.LCPaymentAmount,
          AUDTotalPay: this.model.AUDPaymentAmount,
          PaymentDate: this.model.PaymentDate ? Utils.toVsDateFormat(this.model.PaymentDate) : "",
          PaymentRefNo: this.model.PaymentRefNo,
        } as PayrunPayment;
        const isValid = await (this.$refs.frmPayment as any).validate();
        if (this.model && isValid.valid) {
          await payrunApi.payRunAddPayment(this.selectedPayRun.id, payment);
        }
      } finally {
        this.loading = false;
        this.resetForm();
      }
    },
    resetForm() {
      this.showAddPaymentConfirmDialog = false;
      this.$emit("confirmed", this.selectedPayRun.id);
      if (this.$refs.frmPayment) {
        (this.$refs.frmPayment as any).reset();
      }
    },
    formatDate(date: Date): string {
      return Utils.toVsDateFormat(date);
    },
    formatNumber(value: number): string {
      return Utils.moneyFormat(value);
    },
  },
  computed: {
    currency(): string {
      return this.selectedPayRun?.Currency ?? "";
    },
    formatedPaymentDate() {
      return this.model.PaymentDate ? Utils.toVsDateFormat(this.model.PaymentDate) : null;
    },
  },
});
</script>

<style scoped>
.ref-form {
  width: 100%;
}
</style>
